import * as React from 'react'
import { Platform } from 'react-native'
import { ViewPort } from '../../../../components/Viewport'
import { UserDetailsContext, UserDetailsContextType } from '../../../../provider/auth'
import { Card } from './card'

export const BillingCard = () => {
  const { user } = React.useContext(UserDetailsContext) as UserDetailsContextType

  if (user === null && Platform.OS === 'web') {
    return null
  }

  return (
    <>
      <ViewPort paddingHorizontal="$8" paddingVertical="$4" $gtXs={{ dsp: 'none' }}>
        <Card />
      </ViewPort>
      <ViewPort
        gap="$16"
        fd="row"
        bc="$background"
        theme="alt1"
        dsp="none"
        $gtXs={{ dsp: 'flex', paddingHorizontal: '$10', paddingVertical: '$10' }}
        $gtMd={{ paddingHorizontal: '$20', paddingVertical: '$18' }}
      >
        <Card />
      </ViewPort>
    </>
  )
}
