import { getServerSide } from '@/utils/serverSideFunction/server-side'
import { GetHomeData } from '@my/api'
import { HomeScreen } from 'galaxycard/features/home/screen'
import Head from 'next/head'
import { GetServerSidePropsContext } from 'next/types'

const Page = () => {
  return (
    <>
      <Head>
        <title>Home</title>
      </Head>
      <HomeScreen />
    </>
  )
}

export const getServerSideProps = (ctx: GetServerSidePropsContext) =>
  getServerSide(
    {
      query: GetHomeData,
      variables: {
        withBilling: true,
        withBanners: true,
        products_per_page: 6,
      },
    },
    ctx
  )

export default Page
