import { BodyText, H4, LabelText, tamagui } from '@my/ui'
import { useRootTheme } from 'galaxycard/provider/theme'
import { Skeleton } from 'moti/skeleton'
import { SolitoImage } from 'solito/image'
import { Link } from 'solito/link'
import { StackProps } from 'tamagui'
import { Product as ProductType } from '../../types'

const { YStack, Stack } = tamagui

type Props = StackProps & {
  product?: ProductType
}

export function Product({ product, $gtXs, $gtMd, ...otherProps }: Props) {
  const [theme] = useRootTheme()
  const prodctImg = `https://shuri.voltcred.com/${product?.image?.original_url}`

  return (
    <Link href={`/shopping/product/${product?.primary_variant?.slug}`}>
      <Skeleton.Group show={product === undefined}>
        <YStack gap="$2" $gtXs={{ ...$gtXs, gap: '$6' }} $gtMd={{ ...$gtMd }} {...otherProps}>
          <Skeleton colorMode={theme}>
            <YStack
              boc="$borderColor"
              bw="$0.5"
              br="$1"
              $gtXs={{ br: '$4' }}
              overflow="hidden"
              aspectRatio={1}
              pointerEvents="none"
            >
              {product !== undefined && (
                <SolitoImage
                  fill
                  src={prodctImg}
                  alt={product?.image?.alt ?? 'product_image'}
                ></SolitoImage>
              )}
            </YStack>
          </Skeleton>
          <Skeleton colorMode={theme}>
            <YStack ai="center">
              <H4 ellipsizeMode="tail" numberOfLines={1}>
                {product?.name ?? 'Name'}
              </H4>
              <Stack fd="column" $gtMd={{ fd: 'row', gap: '$3' }}>
                <BodyText weight="$3">
                  ₹{((product?.primary_variant?.price ?? 0) / 10000).toFixed(2)}
                </BodyText>
                {product?.primary_variant?.compare_at_price !== undefined && (
                  <LabelText textDecorationLine="line-through">
                    ₹{(product.primary_variant.compare_at_price / 10000).toFixed(2)}
                  </LabelText>
                )}
                {product?.primary_variant?.compare_at_price !== undefined && (
                  <BodyText weight="$3">
                    {Math.ceil(
                      ((product.primary_variant.compare_at_price - product.primary_variant.price) *
                        100) /
                        product.primary_variant.compare_at_price
                    )}
                    % off
                  </BodyText>
                )}
              </Stack>
            </YStack>
          </Skeleton>
        </YStack>
      </Skeleton.Group>
    </Link>
  )
}
