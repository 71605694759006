import { useFragment } from '@apollo/client'
import { FeaturedCategoriesFragment } from '@my/api'
import { Catalogue } from '../../../../components/Catalogue'
import { Taxon } from '../../../../types'

export const FeaturedCategories = () => {
  const { data } = useFragment({
    fragment: FeaturedCategoriesFragment,
    fragmentName: 'FeaturedCategoriesFragment',
    from: {
      __typename: 'Query',
      id: '{}',
    },
    variables: {
      products_per_page: 6,
    },
  })

  return (
    <>
      {data?.taxons?.map((taxon: Taxon, index: number) => (
        <Catalogue key={`taxons-${taxon?.permalink ?? index}`} taxon={taxon} />
      ))}
    </>
  )
}
