import { AnalyticStack } from '@my/ui'
import * as React from 'react'
import { GestureResponderEvent, Linking } from 'react-native'
import { SolitoImage } from 'solito/image'
import { useRouter } from 'solito/router'
import { StackProps } from 'tamagui'
import { BannerItemType } from '../../types'

type Props = StackProps & {
  banner: BannerItemType
}

export default function Banner({ banner, onPress, ...props }: Props) {
  const router = useRouter()

  const handlePress = React.useCallback(
    (event: GestureResponderEvent) => {
      if (banner.link) {
        Linking.openURL(banner.link)
      } else if (banner.url) {
        router.push({ pathname: banner.url })
      }
      onPress?.(event)
    },
    [banner.link, banner.url, onPress, router]
  )

  return (
    <AnalyticStack onPress={handlePress} key={banner.key} code={banner.key} {...props}>
      <SolitoImage alt="Image not found" fill src={banner.image} contentFit="cover" />
    </AnalyticStack>
  )
}
