import { View, XStack } from 'tamagui'

import * as React from 'react'
import { ViewProps } from 'react-native'

interface Props<T> extends ViewProps {
  onScroll: () => void
}

export default function ScrollButton<T>({ onScroll, children, ...props }: Props<T>) {
  const handleScroll = React.useCallback(() => onScroll(), [onScroll])

  return (
    <XStack {...props} height={'$17'} $xs={{ height: '$7' }} $sm={{ height: '$14' }} ai={'center'}>
      <View $xs={{ display: 'none' }} onPress={handleScroll}>
        {children}
      </View>
    </XStack>
  )
}
