import { AnalyticStack, BodyText, H3, tamagui } from '@my/ui'
import { useRootTheme } from 'galaxycard/provider/theme'
import { Skeleton } from 'moti/skeleton'
import * as React from 'react'
import { FlatList } from 'react-native'
import { SolitoImage } from 'solito/image'
import type { ThemeableStackProps } from 'tamagui'
import LeftArrowIcon from '../../../assets/leftArrow.svg'
import { Product as ProductType, Taxon } from '../../types'
import { ViewPort } from '../Viewport'
import { Product } from './Product'

const { ThemeableStack, View, XStack } = tamagui

type Props = ThemeableStackProps & {
  taxon?: Taxon
}

const renderSpacer = () => <View $gtMd={{ w: '$5' }} $gtXs={{ w: '$4' }} $xs={{ w: '$2' }} />

export function Catalogue({ taxon, ...otherProps }: Props) {
  const flatListRef = React.useRef<FlatList<ProductType | undefined> | null>(null)

  const [index, setIndex] = React.useState(0)

  const scrollLeft = React.useCallback(() => {
    setIndex((currentIndex) => Math.max(currentIndex - 1, 0))
  }, [])

  const scrollRight = React.useCallback(() => {
    setIndex((currentIndex) => Math.min(currentIndex + 1, (taxon?.products?.length ?? 5) - 1))
  }, [taxon?.products?.length])

  React.useEffect(() => {
    flatListRef.current?.scrollToIndex({
      index: Math.max(index, 0),
      animated: true,
    })
  }, [index])

  const renderItem = React.useCallback(({ item: product }: { item: ProductType | undefined }) => {
    return <Product product={product} w="$8" $gtXs={{ w: '$13' }} $gtMd={{ w: '$16' }} />
  }, [])

  const [theme] = useRootTheme()

  return (
    <ThemeableStack
      fd="column"
      backgroundColor="$background"
      gap="$10"
      theme="alt1"
      px="$8"
      py="$6"
      $gtXs={{ px: '$10', py: '$10' }}
      $gtMd={{ px: '$20', py: '$14' }}
      {...otherProps}
    >
      <ViewPort gap="$6">
        <Skeleton.Group show={taxon === undefined}>
          <XStack f={1} jc="space-between" ai="center">
            <Skeleton colorMode={theme}>
              <H3 theme="text">{taxon?.name ?? 'Name'}</H3>
            </Skeleton>
            {taxon !== undefined && (
              <BodyText href={`/shopping/taxon/${taxon.permalink}`}>See All</BodyText>
            )}
          </XStack>
          <XStack $gtXs={{ px: '$10' }} $gtMd={{ px: '$10' }}>
            <FlatList
              data={taxon?.products ?? new Array(5)}
              ref={flatListRef}
              renderItem={renderItem}
              horizontal
              showsHorizontalScrollIndicator={false}
              ItemSeparatorComponent={renderSpacer}
            />
            {taxon !== undefined && (
              <>
                <AnalyticStack
                  onPress={scrollLeft}
                  code={`catalogue-${taxon.id}-left`}
                  dsp="none"
                  $gtMd={{ dsp: 'flex' }}
                  pos="absolute"
                  t={103}
                  l="0"
                >
                  <SolitoImage src={LeftArrowIcon} alt="Left" />
                </AnalyticStack>
                <AnalyticStack
                  onPress={scrollRight}
                  code={`catalogue-${taxon.id}-right`}
                  dsp="none"
                  $gtMd={{ dsp: 'flex' }}
                  pos="absolute"
                  t={103}
                  r="0"
                >
                  <SolitoImage
                    src={LeftArrowIcon}
                    alt="Right"
                    style={{ transform: [{ rotate: '180deg' }] }}
                  />
                </AnalyticStack>
              </>
            )}
          </XStack>
        </Skeleton.Group>
      </ViewPort>
    </ThemeableStack>
  )
}
