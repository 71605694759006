import { useFragment } from '@apollo/client'
import { BannersFragment } from '@my/api'
import { tamagui } from '@my/ui'
import * as React from 'react'
import { LayoutChangeEvent } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'
import {
  CarouselRenderItemInfo,
  ICarouselInstance,
} from 'react-native-reanimated-carousel/lib/typescript/types'
import { SolitoImage } from 'solito/image'
import type { ThemeableStackProps } from 'tamagui'
import LeftArrowIcon from '../../../assets/leftArrow.svg'
import { BannerItemType } from '../../types'
import ScrollButton from '../Catalogue/CatalougeScrollButton'
import { ViewPort } from '../Viewport'
import Banner from './Banner'

const { XStack, useMedia } = tamagui

export function Banners({ onLayout, ...otherProps }: ThemeableStackProps) {
  const { data } = useFragment({
    fragment: BannersFragment,
    fragmentName: 'BannersFragment',
    from: {
      __typename: 'Query',
      id: '{}',
    },
    variables: {
      withBanners: true,
    },
  })

  const banners = data?.banners ?? []

  const [width, setWidth] = React.useState<number>(0)

  const handleOnLayout = React.useCallback(
    (event: LayoutChangeEvent) => {
      onLayout?.(event)
      setWidth(event.nativeEvent.layout.width)
    },
    [onLayout]
  )

  const media = useMedia()

  const height = width / (media.xs ? 3 : media.gtMd ? 4.8 : 2.86)

  const [hasUserInteracted, setUserInteracted] = React.useState(false)

  const carouselRef = React.useRef<ICarouselInstance | null>(null)

  const renderBanner = React.useCallback(
    ({ item }: CarouselRenderItemInfo<BannerItemType>) => (
      <Banner
        banner={item}
        width={width}
        aspectRatio={3}
        $gtXs={{ aspectRatio: 2.86 }}
        $gtMd={{ aspectRatio: 4.8 }}
      />
    ),
    [width]
  )

  const handleUserInteraction = React.useCallback(() => {
    setUserInteracted(true)
    setTimeout(() => setUserInteracted(false), 15000)
  }, [])

  const scrollToLeft = React.useCallback(() => {
    if (carouselRef.current !== null) {
      carouselRef.current.prev()
      handleUserInteraction()
    }
  }, [handleUserInteraction])

  const scrollToRight = React.useCallback(() => {
    if (carouselRef.current !== null) {
      carouselRef.current.next()
      handleUserInteraction()
    }
  }, [handleUserInteraction])

  if ((banners?.length ?? 0) === 0) {
    return null
  }

  return (
    <ViewPort fd="row" onLayout={handleOnLayout} {...otherProps}>
      {width > 0 && (
        <>
          <Carousel
            width={width}
            height={height}
            ref={carouselRef}
            autoPlay={!hasUserInteracted}
            data={banners}
            renderItem={renderBanner}
            autoPlayInterval={3000}
          />
          <XStack
            jc="space-between"
            px="$20"
            als="center"
            pos="absolute"
            t={0}
            width="100%"
            dsp="none"
            $gtMd={{ dsp: 'flex' }}
          >
            <ScrollButton onScroll={scrollToLeft}>
              <SolitoImage src={LeftArrowIcon} alt="Previous" />
            </ScrollButton>
            <ScrollButton onScroll={scrollToRight}>
              <SolitoImage
                src={LeftArrowIcon}
                alt="Next"
                style={{ transform: [{ rotate: '180deg' }] }}
              />
            </ScrollButton>
          </XStack>
        </>
      )}
    </ViewPort>
  )
}
