import { BodyText } from '@my/ui'
import { useRootTheme } from 'galaxycard/provider/theme'
import { Skeleton } from 'moti/skeleton'
import { SolitoImage } from 'solito/image'
import { Link } from 'solito/link'
import { YStack } from 'tamagui'
import { ServiceType } from '../../types'

interface Props {
  service?: ServiceType
}

export function Service({ service }: Props) {
  const [theme] = useRootTheme()

  return (
    <YStack gap="$2" f={1}>
      <Link href={`/services/${service?.code}`}>
        <YStack ai="center" f={1}>
          <Skeleton.Group show={service === undefined}>
            <Skeleton colorMode={theme}>
              <YStack
                width={'$5'}
                $gtXs={{ width: '$10' }}
                $gtMd={{ width: '$11.25' }}
                style={{ aspectRatio: 1 }}
              >
                {service !== undefined && (
                  <SolitoImage
                    alt={service.name}
                    src={`https://api.paysay.in/v1/files/${service.picture_id}`}
                    fill
                  />
                )}
              </YStack>
            </Skeleton>
            <Skeleton colorMode={theme}>
              <BodyText theme="text" numberOfLines={1} ellipsizeMode="tail">
                {service?.name ?? 'Name'}
              </BodyText>
            </Skeleton>
          </Skeleton.Group>
        </YStack>
      </Link>
    </YStack>
  )
}
