import { useFragment, useLazyQuery } from '@apollo/client'
import { BillingFragment, UserAppStatus } from '@my/api'
import { BodyText, Button, H1, H2, H4, tamagui } from '@my/ui'
import { LabelText } from '@my/ui/'
import * as React from 'react'
import { SolitoImage } from 'solito/image'
import { Link } from 'solito/link'
import { useRouter } from 'solito/router'
import CardBackground from '../../../../assets/card.svg'
import PLAY_STORE from '../../../../assets/googleplay.svg'
import PSLOGO from '../../../../assets/pslogo.svg'
import { usePlayStoreUrl } from '../../../../hooks/usePlayStoreUrl'
import { UserDetailsContext, UserDetailsContextType } from '../../../../provider/auth'

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const { Separator, XStack, YStack } = tamagui

export const Card = () => {
  const url = usePlayStoreUrl('utm_channel=website&utm_source=download-home')
  const { user } = React.useContext(UserDetailsContext) as UserDetailsContextType

  const [AppStatus, { data: appStatus }] = useLazyQuery(UserAppStatus, {
    fetchPolicy: 'no-cache',
  })

  const { data } = useFragment({
    fragment: BillingFragment,
    fragmentName: 'BillingFragment',
    from: {
      __typename: 'Billing',
      id: '{}',
    },
    variables: {
      withBilling: true,
    },
  })

  const router = useRouter()

  const { balance, limit, bill, endsAt, lastPayBy } = data ?? {}

  const makeDate = (time: number) => {
    const date = new Date(time * 1000)
    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`
  }

  const timeTo = (time: number, func: keyof Pick<Math, 'floor' | 'ceil' | 'round'> = 'floor') => {
    const now = Date.now() / 1000
    const seconds = Math.abs(now - time)
    return Math[func](seconds / 86400)
  }

  const billAndDueDate = React.useMemo(() => {
    if (lastPayBy) {
      const now = Date.now() / 1000
      if (lastPayBy < now) {
        return (
          <BodyText weight={'$3'} color={'$blue1'}>
            {Math.ceil((bill ?? 0) / 100) / 100} are overdue since {timeTo(lastPayBy, 'ceil')} days
          </BodyText>
        )
      } else if (lastPayBy >= now) {
        return (
          <BodyText weight={'$3'} color={'$blue1'}>
            Bill of {Math.ceil((bill ?? 0) / 100) / 100} due in {timeTo(lastPayBy)} days
          </BodyText>
        )
      }
    } else if (endsAt) {
      return (
        <BodyText weight={'$3'} color={'$blue1'}>
          Next bill date {makeDate(endsAt)}
        </BodyText>
      )
    }
  }, [bill, endsAt, lastPayBy])

  const handlePayButton = React.useCallback(() => {
    router.push('/pay')
  }, [router])

  const handleLoginButton = React.useCallback(() => {
    router.push('/login')
  }, [router])

  const handleActivateCreditLimit = React.useCallback(() => {
    return AppStatus()
  }, [AppStatus])

  React.useEffect(() => {
    if (appStatus?.documents?.polling?.url) {
      router.push(appStatus?.documents?.polling?.url)
    }
  }, [appStatus, router])
  
  const canPay = React.useMemo(() => user?.status === 'approved' || (balance ?? 0) < (limit ?? 0), [balance, limit, user?.status])

  return (
    <>
      <YStack
        h={210}
        w={'100%'}
        br="$1"
        $gtXs={{ h: '$16', w: '$18', br: '$2' }}
        $gtMd={{ h: '$17.25', w: '$19', br: '$4' }}
        backgroundColor={'$blue8'}
      >
        <YStack paddingVertical={'$7'} width={'100%'} height={'100%'} f={1}>
          <SolitoImage src={CardBackground} alt="card-background" fill contentFit="cover" />
        </YStack>
        <YStack
          pos={'absolute'}
          width={'100%'}
          height={'100%'}
          gap={'$8'}
          paddingVertical={'$7'}
          $gtMd={{ paddingVertical: '$10' }}
        >
          <XStack jc={'space-between'} $gtMd={{ marginHorizontal: '$10' }} marginHorizontal={'$7'}>
            <YStack h={'$3'} w={'$3'} $gtMd={{ h: '$5', w: '$5' }}>
              <SolitoImage src={PSLOGO} alt="ps-logo" fill />
            </YStack>
            <LabelText color={'$blue1'}>{user?.user_id === undefined ? 'Hi' : ''}</LabelText>
          </XStack>
          {user === null || user === undefined ? (
            <H2 px={'$7'} py={'$3'} color="$blue1" weight="$3">
              Welcome to PaySay
            </H2>
          ) : (
            <XStack
              backgroundColor={'$blue1'}
              paddingVertical={'$3'}
              paddingHorizontal={'$7'}
              borderTopRightRadius={'$1'}
              borderBottomRightRadius={'$1'}
              gap={'$7'}
              width={'max-content'}
              alignSelf="flex-start"
              $gtMd={{
                paddingVertical: '$4',
                paddingHorizontal: '$10',
                gap: '$10',
                borderTopRightRadius: '$2',
                borderBottomRightRadius: '$2',
              }}
            >
              <YStack>
                <LabelText weight={'$2'}>₹ {balance ? (balance / 10000).toFixed(2) : 0}</LabelText>
                <LabelText>Balance</LabelText>
              </YStack>
              <Separator vertical backgroundColor={'$blue10'} />
              <YStack>
                <LabelText weight={'$2'}>₹ {limit ? (limit / 10000).toFixed(2) : 0}</LabelText>
                <LabelText>Credit Limit</LabelText>
              </YStack>
            </XStack>
          )}
          <XStack
            jc={billAndDueDate ? 'space-between' : 'flex-end'}
            ai={'flex-end'}
            marginTop={'$8'}
            marginHorizontal={'$7'}
            $gtMd={{ marginHorizontal: '$10' }}
          >
            {billAndDueDate}
            <Button
              code={
                (user ?? null) === null ? 'login' : canPay ? 'pay-now' : 'activate-limit'
              }
              bc={'$redish8'}
              boc={'$blue1'}
              bw={'$0.5'}
              onPress={
                (user ?? null) === null
                  ? handleLoginButton
                  : canPay
                  ? handlePayButton
                  : handleActivateCreditLimit
              }
            >
              <Button.Text>
                {(user ?? null) === null ? 'Login' : canPay ? 'Pay Now' : 'Activate Limit'}
              </Button.Text>
            </Button>
          </XStack>
        </YStack>
      </YStack>
      <YStack dsp="none" $gtXs={{ dsp: 'flex' }} f={1}>
        <H1>Download Now</H1>
        <H4>Get started instantly with your instant credit limit</H4>
        <Link href={url}>
          <YStack h={65} w={198} mt={24} $sm={{ h: 28, w: 84 }}>
            <SolitoImage alt="playStore" src={PLAY_STORE} fill contentFit="cover" />
          </YStack>
        </Link>
      </YStack>
    </>
  )
}
