import { useQuery } from '@apollo/client'
import { GetHomeData } from '@my/api'
import { subscribe } from '@my/notifications'
import { BodyText, H3, tamagui } from '@my/ui'
import type { FirebaseMessagingTypes } from '@react-native-firebase/messaging'
import * as React from 'react'
import { YStackProps } from 'tamagui'
import { Banners as BannersView } from '../../components/Banners'
import { Service } from '../../components/Services'
import { ViewPort } from '../../components/Viewport'
import { ServiceType } from '../../types'
import { BillingCard } from './sections/billing'
import { FeaturedCategories } from './sections/featuredCategories'
import { SolitoImage } from 'solito/image'
import SEE_ALL from '../../assets/seeAll.svg'
import { Link } from 'solito/link'

const { YStack, useMedia, ThemeableStack, XStack } = tamagui

export function HomeScreen({ $gtXs, $gtMd, ...props }: YStackProps) {
  const { data, refetch } = useQuery(GetHomeData, {
    variables: {
      withBanners: true,
      withBilling: true,
      products_per_page: 6,
    },
  })

  React.useEffect(() => {
    const handler = async function (payload: FirebaseMessagingTypes.RemoteMessage) {
      const data = payload.data
      if (data?.key === 'sync') {
        refetch()
      }
    }
    const destroyer = subscribe(handler)
    return () => {
      destroyer()
    }
  }, [refetch])

  const services = data?.services.filter(({ enabled }: ServiceType) => enabled)
  const media = useMedia()
  const iconCount = React.useMemo(() => (media.sm ? 2 : media.md ? 4 : 6), [media.sm, media.md])

  return (
    <YStack gap="$8" $gtXs={{ gap: '$10', ...$gtXs }} $gtMd={{ gap: '$17', ...$gtMd }} {...props}>
      <BannersView f={1} />
      <BillingCard />
      <ThemeableStack
        fd="column"
        backgroundColor="$background"
        gap="$10"
        theme="alt1"
        px="$8"
        py="$6"
        $gtXs={{ px: '$10', py: '$10' }}
        $gtMd={{ px: '$20', py: '$14' }}
      >
        <ViewPort>
          <H3 theme="text">Services</H3>
          <XStack gap="$12">
            {services?.slice(0, iconCount + 1).map((service: ServiceType, index: number) => (
              <Service service={service} key={service?.id ?? index} />
            ))}
            {(services?.length ?? 0) > iconCount + 1 && (
              <YStack gap="$2" f={1}>
                <Link href={'/services'}>
                  <YStack ai="center" f={1}>
                    <YStack
                      width={'$5'}
                      $gtXs={{ width: '$10' }}
                      $gtMd={{ width: '$11.25' }}
                      style={{ aspectRatio: 1 }}
                    >
                      <SolitoImage alt={'See All'} src={SEE_ALL} fill />
                    </YStack>
                    <BodyText theme="text" numberOfLines={1} ellipsizeMode="tail">
                      See All
                    </BodyText>
                  </YStack>
                </Link>
              </YStack>
            )}
          </XStack>
        </ViewPort>
      </ThemeableStack>
      <FeaturedCategories />
    </YStack>
  )
}
