import { getSize } from '@tamagui/get-token'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { GestureResponderEvent } from 'react-native'
import { useSharedValue } from 'react-native-reanimated'
import {
  GetProps,
  Button as TButton,
  ButtonContext as TButtonContext,
  TamaguiElement,
  styled,
  useTheme,
  withStaticProperties,
} from 'tamagui'
import useAnalyticsPressable, { AnalyticPressableProps } from '../hooks/useAnalyticsPressable'

const ButtonFrame = styled(TButton, {
  name: 'Button',
  variants: {
    unstyled: {
      false: {
        bw: 0,
        category: 'large',
      },
    },
    appearance: {
      outlined: {
        variant: 'outlined',
        boc: '$background',
      },
      text: {
        variant: 'outlined',
      },
    },
    fluid: {
      true: {
        width: '100%',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$gray5',
      },
    },
    category: {
      large: {
        height: '$4',
        px: '$9',
        $gtXs: {
          height: '$4.5',
          px: '$10',
        },
      },
      small: {
        height: '$3',
        px: '$8',
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})

const ButtonText = styled(TButton.Text, {
  name: 'Button',
  variants: {
    size: (val) => {
      if (val === '$true') {
        const size = getSize(val, {
          shift: val === '$true' ? -1 : 0,
        })

        return {
          fontSize: size,
          $gtXs: {
            fontSize: val,
          },
        }
      }

      return {
        fontSize: val,
      }
    },
  } as const,
})

const ButtonIcon = (props: { children: React.ReactElement }) => {
  // TODO: revist this someday to find how we can get this to work
  // const { size } = React.useContext(TButtonContext)
  // let style
  // if (size === '$true') {
  //   const shiftedSize = getSize(size, {
  //     shift: size === '$true' ? -1 : 0,
  //   })
  //   style = {
  //     height: shiftedSize.val * 0.5,
  //     width: shiftedSize.val * 0.5,
  //     $gtXs: {
  //       height: getSize(size).val * 0.5,
  //       width: getSize(size).val * 0.5,
  //     },
  //   }
  // } else {
  //   style = {
  //     height: getSize(size).val * 0.5,
  //     width: getSize(size).val * 0.5,
  //   }
  // }
  const theme = useTheme()
  return React.cloneElement(props.children, {
    // ...style,
    color: theme.color.get(),
  })
}

export type ButtonRef = TamaguiElement & {
  submit: (event?: GestureResponderEvent) => unknown | void | Promise<unknown>
}

type ButtonProps = GetProps<typeof ButtonFrame> & AnalyticPressableProps

const ButtonComponent = ButtonFrame.styleable<ButtonProps>(
  ({ disabled, onPress, code, params, ...rest }: ButtonProps, ref) => {
    const { handlePress } = useAnalyticsPressable({ onPress, code, params })

    const [loading, setLoading] = React.useState(false)
    const progress = useSharedValue(0)

    const formik = useFormikContext()

    const handleButtonPress = React.useCallback(
      (e: GestureResponderEvent | React.FormEvent<HTMLFormElement>) => {
        const result = handlePress(e as GestureResponderEvent)
        if (onPress instanceof Function) {
          setLoading(true)
          progress.value = 0
          if (result instanceof Promise) {
            result
              .then((cb) => cb?.())
              .finally(() => {
                setLoading(false)
                // cancelAnimation(progress);
              })
          } else {
            setLoading(false)
          }
        } else if (formik?.handleSubmit) {
          formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
        }
      },
      [formik, handlePress, onPress, progress]
    )

    return (
      <ButtonFrame
        ref={ref}
        onPress={handleButtonPress}
        {...rest}
        disabled={formik?.isSubmitting || disabled || loading}
      />
    )
  }
)

ButtonComponent.displayName = 'Button'

export const Button = withStaticProperties(ButtonComponent, {
  Text: ButtonText,
  Icon: ButtonIcon,
  Props: TButtonContext.Provider,
})
